<template>
  <!--begin::List Widget 4-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">‌Monthly‌ ‌Vaccination‌ ‌Session</h3>
      <div class="card-toolbar">
        <Dropdown4
          :btnClass="
            'btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle'
          "
          :btnText="'August'"
        ></Dropdown4>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10" v-bind:key="i">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 symbol-light-success mr-5">
            <span class="symbol-label">
              <inline-svg
                :src="item.svg"
                class="h-75 align-self-end"
              ></inline-svg>
            </span>
          </div>
          <!--end::Symbol-->
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <a class="text-dark text-hover-primary mb-1 font-size-lg">
              {{ item.title }}
            </a>
            <span class="text-muted">
              {{ item.desc }}
            </span>
          </div>
          <!--end::Text-->
          <!--begin::Dropdown-->
          <!-- <Dropdown2><i class="ki ki-bold-more-hor"></i></Dropdown2> -->
          <!--end::Dropdown-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 4-->
</template>

<script>
// import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import Dropdown4 from "@/view/content/dropdown/Dropdown4.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-3",
  data() {
    return {
      list: [
        {
          title: "Hepatitis B Vaccine",
          desc: "A vaccine-preventatable disease that can lead to kiver cancer",
          svg: "media/svg/icons/Home/Library.svg",
          style: "primary"
        },
        {
          title: "Hepatitis A Vaccine",
          desc: "Hepatitis A is a serious liver disease caused by a virus, which is found in the stool of people with the disease",
          svg: "media/svg/icons/Communication/Write.svg",
          style: "warning"
        },
        {
          title: "Diphtheria, Tetanus, and Pertussis Vaccine",
          desc: "This combination vaccine (DTaP) is composed of vaccines against diphtheria, tetanus (lockjaw), and pertussis (whooping cough)",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          style: "success"
        }
       
      ]
    };
  },
  components: {
    // Dropdown2,
    Dropdown4
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>
