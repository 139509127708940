<template>
  <!--begin::Stats Widget 10-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <span class="symbol  symbol-50 symbol-light-primary mr-2">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
              <inline-svg src="media/svg/icons/Shopping/Cart3.svg" />
              <!--end::Svg Icon-->
            </span>
          </span>
        </span>
        <div class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h3">Statitics Overview</span>
          <!-- <span class="text-muted font-weight-bold mt-2">Sales Change</span> -->
        </div>
      </div>
      <!--begin::Chart-->
      <apexchart
        
        :options="chartOptions"
        :series="series"
        type="pie"
        width="350"
      ></apexchart>


      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 10-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [44, 55, 41]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    // reference; kt_stats_widget_12_chart
    this.chartOptions = {
      chart: {
        type: "pie",
        width: 450,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      labels: ['No of Partners', 'Successful Vaccinations', 'Vaccination not taken '],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'top'
                }
              }
            }],
      plotOptions: {},
      // legend: {
      //   show: false
      // },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.base.primary")]
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      // tooltip: {
      //   style: {
      //     fontSize: "12px",
      //     fontFamily: this.layoutConfig("font-family")
      //   },
      //   y: {
      //     formatter: function(val) {
      //       return "$" + val + " thousands";
      //     }
      //   }
      // },
      // colors: [this.layoutConfig("colors.theme.light.primary")],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.primary")],
        strokeColor: [this.layoutConfig("colors.theme.base.primary")],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  }
};
</script>
